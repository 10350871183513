'use strict';

import * as ko from 'knockout';
import Modal from '../Common/Modal';

export class AlertViewModel {
    public errorMessage: KnockoutObservable<string>;
    public closeAlertCallback: any;

    constructor() {
        this.errorMessage = ko.observable('');
    }

    public openAlert(error: string): void {
        this.errorMessage(error);
        Modal.show('alert-modal');
    }

    public closeAlert(): void {
        try {
            Modal.close('alert-modal');
        } catch (e) {
            // Ignore
        }
        if (this.closeAlertCallback != null) {
            this.closeAlertCallback();
        }
    }
}
