export class FetchError<T> extends Error {
    data: T;
    status: number;
    statusText: string;

    constructor(response: Response, data: T) {
        super(
            `Request failed with status ${response.status} ${response.statusText}`,
        );
        this.data = data;
        this.status = response.status;
        this.statusText = response.statusText;
    }
}

export async function fetch<T>(
    url: string,
    method = 'GET',
    data?: any,
    contentType?: string,
): Promise<T> {
    const headers: HeadersInit = {
        'Accept-Language': 'da',
    };
    if (data !== undefined) {
        if (typeof data === 'string' && contentType) {
            headers['content-type'] = contentType;
        } else {
            data = JSON.stringify(data);
            headers['content-type'] = 'application/json';
        }
    }

    const res = await window.fetch(url, {
        method,
        body: data,
        headers,
    });

    let returnValue: T;
    if (res.headers.get('content-type')?.startsWith('application/json')) {
        returnValue = await res.json();
    } else {
        returnValue = (await res.text()) as any;
    }

    if (res.status >= 400) {
        throw new FetchError(res, returnValue);
    }

    return returnValue;
}
