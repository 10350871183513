import '../js/modernizr-custom.js';

import { AvailableRelationTypeEnum, PartnerStatusEnum } from './enums';
import { fetch } from './fetch';
import { Log } from './Log';
import { Texts } from './Texts';
import {
    differenceInYears,
    format,
    formatRelative,
    parse,
    parseISO,
} from 'date-fns';

declare let Modernizr: any;

export class Utils {
    public static getParameterByName(name: string, url?: string): string {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    public static logout(): void {
        sessionStorage.clear();
        localStorage.clear();
        document.location.href = '/authentication/logoff';
    }

    public static getFullUrlAddress(url: string): string {
        return (
            window.location.protocol + '//' + window.location.host + '/' + url
        );
    }

    public static getCookie = (name: string): string => {
        const value = '; ' + document.cookie;
        const parts = value.split('; ' + name + '=');
        return parts[1]?.split(';').shift();
    };

    public static formatPrice(amount: number): string {
        if (amount === null) {
            return '';
        }
        const hasSubunits = amount % 1 !== 0;
        const n = hasSubunits ? 2 : 0; // decimals
        const x = 3; // whole part length
        const s = '.'; // section delimiter
        const c = ','; // decimal delimiter

        const re = '\\d(?=(\\d{' + x + '})+' + (n > 0 ? '\\D' : '$') + ')';
        const num = amount.toFixed(Math.max(0, ~~n));

        const price = num
            .replace('.', c)
            .replace(new RegExp(re, 'g'), '$&' + s);
        return hasSubunits ? price : price + ',-';
    }

    public static formatShortDate = (date: string | Date): string => {
        return Utils.formatDate(date, Texts.get('dateFnsShortDateFormat'));
    };

    public static formatTime = (date: string | Date): string => {
        return Utils.formatDate(date, Texts.get('dateFnsTimeFormat'));
    };

    public static formatRelativeDateTime(date: string): string {
        return formatRelative(parseISO(date), new Date());
    }

    public static formatShortDateTime(date: string | Date): string {
        return Utils.formatDate(date, Texts.get('dateFnsShortDateTimeFormat'));
    }

    public static formatLongDateTime(date: string | Date): string {
        return Utils.formatDate(date, Texts.get('dateFnsLongDateTimeFormat'));
    }

    public static formatLongDate(date: string | Date): string {
        return Utils.formatDate(date, Texts.get('dateFnsLongDateFormat'));
    }

    private static formatDate(date: Date | string, formatString: string) {
        if (!date) {
            return '';
        }
        if (typeof date === 'string') {
            date = parseISO(date);
        }
        return format(date, formatString);
    }

    public static relationTypeText(
        relationType: AvailableRelationTypeEnum,
    ): string {
        switch (relationType) {
            case AvailableRelationTypeEnum.Child:
                return Texts.getResource('Child');
            case AvailableRelationTypeEnum.Partner:
                return Texts.getResource('Partner');
            default:
                return '';
        }
    }

    public static getApiUrl(url: string): string {
        return (
            window.location.protocol + '//' + window.location.host + '/' + url
        );
    }

    public static fetch<T>(
        url: string,
        method = 'GET',
        data?: any,
        contentType?: string,
    ): Promise<T> {
        return fetch(url, method, data, contentType);
    }

    /**
     * @deprecated use Utils.fetch instead.
     */
    public static ajaxCall(
        requestType: string,
        url: string,
        successCallBack: (
            data: any,
            textStatus: string,
            jqXhr: JQueryXHR,
        ) => any,
        errorCallBack?: (
            jqXhr: JQueryXHR,
            textStatus: string,
            error: string,
        ) => any,
        data?: any,
        contentType?: string,
        isAsync?: boolean,
    ) {
        const settings: JQueryAjaxSettings = {
            async: isAsync ? isAsync : true,
            contentType: contentType ? contentType : 'application/json',
            data,
            error: (request: JQueryXHR, status: string, error: string) => {
                if (request.status === 401) {
                    this.logout();
                    return;
                }

                if (errorCallBack) {
                    errorCallBack(request, status, error);
                    return;
                }

                Log.error(
                    `API call failed and no error callback provided. url=${url} status=${request.status} response=${request.responseJSON}`,
                );
            },
            success: successCallBack,
            url: this.getApiUrl(url),
            type: requestType,
            headers: {
                'Accept-Language': 'da',
            },
        };

        $.ajax(settings);
    }

    public static getPartnerStatus = (status: PartnerStatusEnum): string => {
        switch (status) {
            case PartnerStatusEnum.NoPartner:
                return Texts.getResource('NoPartner');
            case PartnerStatusEnum.Invited:
                return Texts.getResource('Invited');
            case PartnerStatusEnum.Active:
                return Texts.getResource('Active');
            default:
                return '';
        }
    };

    /**
     * Calls a function repeatedely at the specified interval.
     *
     * @param millis: the time to wait in between executions
     * @param callback: the function to call
     *
     * @returns a handle function used to cancel/restart the execution
     */
    public static loop(millis: number, callback: any): () => any {
        let cancel = false;
        const loop = () => {
            if (!cancel) {
                window.setTimeout(() => {
                    if (!cancel) {
                        callback();
                        loop();
                    }
                }, millis);
            }
        };
        loop();
        return () => {
            cancel = true;
            return () => {
                return Utils.loop(millis, callback);
            };
        };
    }

    public static timeSpanStringToMinutes(timeSpan: string): number {
        const values = timeSpan.split(':');
        const hours = Number(values[0]);
        const minutes = Number(values[1]);
        return hours * 60 + minutes;
    }

    /**
     *  Does nothing
     */
    public static noop() {
        /**/
    }

    /* eslint-disable @typescript-eslint/no-unsafe-function-type */

    /**
     *  Creates a version of the function that can only be called one time.
     *  Repeated calls to the modified function will have no effect,
     *  returning the value from the original call.
     *
     *  NOTE: The result of the first call's set of arguments will be memomized
     *  and returned for all other calls regardless of the arguments passed to
     *  subsequent calls as the underlying function is never called again.
     */
    public static once(func: Function) {
        let ran = false;
        let memo: any;
        return (
            func &&
            function () {
                if (ran) {
                    return memo;
                }
                ran = true;
                memo = func.apply(this, arguments);
                func = null;
                return memo;
            }
        );
    }

    /**
     * Partially applies a function
     * @param func the function
     * @param pArgs the partial aguments
     */
    public static pApply(func: Function, ...pArgs: any[]) {
        return (...args: any[]) => func(...[...pArgs, ...args]);
    }

    public static loopAsync(
        millis: number,
        func: Function,
        callback: Function,
    ) {
        let cancel = false;
        const loop = () => {
            if (!cancel) {
                window.setTimeout(() => {
                    if (!cancel) {
                        func((...args: any[]) => {
                            if (!cancel) {
                                callback(...args);
                                loop();
                            }
                        });
                    }
                }, millis);
            }
        };
        loop();
        return () => {
            cancel = true;
            return () => {
                Utils.loopAsync(millis, func, callback);
            };
        };
    }

    public static isWebRtcBrowser(): boolean {
        return (
            Modernizr.getusermedia &&
            (Modernizr.datachannel || Modernizr.peerconnection)
        );
    }

    public static isMobileOrTablet(): boolean {
        let check = false;
        ((a) => {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                    a,
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4),
                )
            ) {
                check = true;
            }
        })(navigator.userAgent || navigator.vendor || (window as any).opera);
        return check;
    }

    public static isIos(): boolean {
        return navigator.userAgent.match(/iPhone|iPad/i) !== null;
    }

    public static async isAzureSupportingDevice(): Promise<boolean> {
        const azureCalling = await import('@azure/communication-calling');
        const callClient = new azureCalling.CallClient();
        const info = await callClient
            .feature(azureCalling.Features.DebugInfo)
            .getEnvironmentInfo();

        return info.isSupportedEnvironment;
    }

    public static isVideoDevice(): boolean {
        return Utils.isWebRtcBrowser() && !Utils.isIos() && !Utils.isOldEdge();
    }

    // returns true if unsupported
    public static redirectIfUnsupported(): boolean {
        // prevent redirect loops
        if (/^\/unsupported/i.test(document.location.pathname)) {
            return false;
        }

        if (this.isVideoDevice()) {
            return false;
        }

        if (this.isAndroid()) {
            document.location.href = '/unsupported/android';
            return true;
        }

        if (this.isIos()) {
            document.location.href = '/unsupported/ios';
            return true;
        }

        document.location.href = '/unsupported/desktop';
        return true;
    }

    private static isAndroid(): boolean {
        return /android/i.test(navigator.userAgent);
    }

    private static isOldEdge(): boolean {
        return /edge\//i.test(navigator.userAgent);
    }

    public static formatCpr(cpr: string): string {
        if (cpr && cpr.length >= 6) {
            return cpr.slice(0, 6) + '-' + cpr.slice(6);
        }
        return cpr;
    }

    public static cprModulusCheck(cpr: string): boolean {
        if (cpr.length < 10) return true;

        cpr = cpr.replace(/[-\s]/gi, '');

        let mainInt = 0;
        const factors = [4, 3, 2, 7, 6, 5, 4, 3, 2, 1];

        for (let i = 0; i < cpr.length; i++) {
            const cprDigit = parseInt(cpr.substring(i, i + 1));
            mainInt += cprDigit * factors[i];
        }

        return mainInt % 11 === 0;
    }
}
